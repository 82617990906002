body {
  font-family: "Inter", sans-serif;
  margin: 0;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f;
  text-align: left;
  background-color: #f4f7fc;
  overflow-x: hidden;
  overflow-y: auto;
}
.bg-brand {
  background-color: #86c334;
}
.brand-color {
  color: #86c334;
}
.brand-color:hover {
  color: #86c334;
}
.addPadding {
  padding: 20px 10px 20px 20px;
}

.centerBorder {
  text-align: center;
  border: 1px solid black;
}

.center {
  text-align: center;
}
.noResize {
  resize: none;
}
.textbox {
  word-wrap: break-word;
  height: 80px;
}
.login-form,
.externalContainer,
.changepassword-form {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control:focus {
  box-shadow: none !important;
}
.btn:focus {
  box-shadow: none;
}
.btn-login {
  background: #86c334;
  color: #fff;
  letter-spacing: 1px;
}
.btn-login:hover,
.btn-login:focus {
  background: #5b8523;
  box-shadow: 0 1px 1px 0 rgba(91, 133, 35, 0.45),
    0 1px 3px 1px rgba(91, 133, 35, 0.3);
  color: #fff;
  text-transform: none;
}
.btn-link:hover,
a:hover {
  text-decoration: none;
}
.fixed-margin-top {
  margin-top: 55px;
}
.navbar-color {
  background-color: #e3f2fd;
}
.card {
  border-radius: 0.5rem !important;
}
.ReactTable {
  border-radius: 0.5rem;
  border: none !important;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.ReactTable .rt-thead .rt-th {
  padding: 10px 12px !important;
}
.ReactTable .rt-tr {
  align-items: center;
}
.ReactTable .rt-tbody .rt-td {
  font-weight: 500;
  padding: 7px 12px;
}
.ReactTable .rt-noData {
  font-weight: 600;
  color: rgba(0, 0, 0, 1) !important;
  top: 49% !important;
  background: transparent !important;
  padding: 0 !important;
}
.ReactTable .-pagination {
  box-shadow: none !important;
}
.vida-navbar button {
  border-color: transparent !important;
}
.vida-navbar button:focus {
  outline: none;
}
.nav-item .nav-link.active {
  font-weight: 500;
  color: #86c334 !important;
}
.nav-link:hover {
  color: #86c334 !important;
}
.card {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-color: #edf2f9;
  border-radius: 0.25rem;
}
.table th {
  border-top: 0px;
}
.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}
.table-wrap td {
  white-space: pre-wrap;
}
.table-wrap thead {
  white-space: nowrap;
}
.table td {
  font-weight: 500;
}
.table-striped tbody tr:nth-of-type(odd) {
  background: rgba(0, 0, 0, 0.03) !important;
}
table th .sort:hover {
  cursor: pointer;
}
table th.active {
  color: #86c334;
}
.btn-vida,
.btn-vida:focus {
  background: #86c334;
  border-color: #86c334;
  color: #fff;
}
.btn-vida:hover {
  background: #5b8523;
  border-color: #5b8523;
  color: #fff;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #f8f9fa;
}
.logo {
  width: 200px;
}
.main-nav .dropdown-item {
  padding: 0;
}
.main-nav .dropdown-item a {
  padding: 0.25rem 1rem;
}
.noWrap {
  white-space: nowrap;
}

/* Progress Bar */
.progress {
  position: absolute;
  height: 5px;
  display: block;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  overflow: hidden;
  top: -0.002rem;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0;
}
.progress .indeterminate {
  background-color: #b0d87b;
}
.progress .indeterminate:before {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.progress .indeterminate:after {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  animation-delay: 1.15s;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.initial-app-loader {
  height: 100vh;
}

/* Toast Notification */

.registration {
  height: 100vh;
}

/* Pagination */
.pagination .page-item .page-link:hover {
  cursor: pointer;
}
.page-item.active .page-link {
  background: #86c334 !important;
  color: #fff !important;
  border-color: #86c334 !important;
}

.card-header,
.card-footer {
  background-color: rgba(121, 191, 53, 0.3) !important;
}
.custom-control {
  top: 4px !important;
}

.custom-control-label::after,
.custom-control-label::before {
  top: 0;
}
.custom-checked .custom-control-label::before {
  top: 0rem;
}
.custom-checked .custom-control-label::after {
  top: 0.1rem;
}
.secure-nav-logo {
  width: 100px;
}
.filedownload:hover {
  cursor: pointer;
}
.td-feedback {
  width: 600px;
}
.isAssetname {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  font-size: 1rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.dropdown-item:hover {
  background-color: rgba(121, 191, 53, 0.3) !important;
}
.dropdown-item a {
  font-size: 0.9rem;
  padding: 0.3rem 1rem !important;
}
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
}
@media (max-width: 599px) {
  .xs-width-200 {
    width: 200px;
  }
  .image-text .phone {
    position: relative !important;
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
  }
  .image-text h5 {
    padding-left: 0% !important;
    z-index: 10;
    text-align: center;
  }
}
.image-text {
  padding-top: 20%;
  position: relative;
}
.image-text .phone {
  position: absolute;
  width: 70%;
  left: -6rem;
  bottom: -2px;
  z-index: 0;
}
.image-text h5 {
  padding-left: 50%;
  z-index: 10;
}
/* .image-text .phone{
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
}
.image-text h6{
  padding-left: 0% !important;
  z-index: 10;
} */

table .disabled {
  user-select: none;
  background-color: rgba(0, 0, 0, 0.05);
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #eee, -1px 0 0 #eee, 0 1px 0 #eee, 0 -1px 0 #eee,
    0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #eee, -1px 0 0 #eee, 0 1px 0 #eee, 0 -1px 0 #eee,
    0 3px 13px rgba(0, 0, 0, 0.08);
}
.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #eee;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #eee;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #eee;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-months .flatpickr-month {
  background: transparent;
  color: #3c3f40;
  fill: #3c3f40;
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: #3c3f40;
  fill: #3c3f40;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #f64747;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}
.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(64, 72, 72, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(64, 72, 72, 0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(64, 72, 72, 0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(60, 63, 64, 0.5);
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #3c3f40;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #3c3f40;
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(60, 63, 64, 0.5);
  background: transparent;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}
.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #eee;
  box-shadow: -1px 0 0 #eee;
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #404848;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e9e9e9;
  border-color: #e9e9e9;
}
.flatpickr-day.today {
  border-color: #f64747;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #f64747;
  background: #f64747;
  color: #fff;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #4f99ff;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #4f99ff;
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 #4f99ff;
  box-shadow: -10px 0 0 #4f99ff;
}
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e9e9e9, 5px 0 0 #e9e9e9;
  box-shadow: -5px 0 0 #e9e9e9, 5px 0 0 #e9e9e9;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(64, 72, 72, 0.3);
  background: transparent;
  border-color: #e9e9e9;
  cursor: default;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(64, 72, 72, 0.1);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #4f99ff, 5px 0 0 #4f99ff;
  box-shadow: -5px 0 0 #4f99ff, 5px 0 0 #4f99ff;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #eee;
  box-shadow: 1px 0 0 #eee;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(64, 72, 72, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #404848;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #404848;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #404848;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #404848;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #f1f1f1;
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.flatpickr-calendar {
  width: 307.875px;
}
.dayContainer {
  padding: 0;
  border-right: 0;
}
span.flatpickr-day,
span.flatpickr-day.prevMonthDay,
span.flatpickr-day.nextMonthDay {
  border-radius: 0 !important;
  border: 1px solid #e9e9e9;
  max-width: none;
  border-right-color: transparent;
}
span.flatpickr-day:nth-child(n + 8),
span.flatpickr-day.prevMonthDay:nth-child(n + 8),
span.flatpickr-day.nextMonthDay:nth-child(n + 8) {
  border-top-color: transparent;
}
span.flatpickr-day:nth-child(7n-6),
span.flatpickr-day.prevMonthDay:nth-child(7n-6),
span.flatpickr-day.nextMonthDay:nth-child(7n-6) {
  border-left: 0;
}
span.flatpickr-day:nth-child(n + 36),
span.flatpickr-day.prevMonthDay:nth-child(n + 36),
span.flatpickr-day.nextMonthDay:nth-child(n + 36) {
  border-bottom: 0;
}
span.flatpickr-day:nth-child(-n + 7),
span.flatpickr-day.prevMonthDay:nth-child(-n + 7),
span.flatpickr-day.nextMonthDay:nth-child(-n + 7) {
  margin-top: 0;
}
span.flatpickr-day.today:not(.selected),
span.flatpickr-day.prevMonthDay.today:not(.selected),
span.flatpickr-day.nextMonthDay.today:not(.selected) {
  border-color: #e9e9e9;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: #f64747;
}
span.flatpickr-day.today:not(.selected):hover,
span.flatpickr-day.prevMonthDay.today:not(.selected):hover,
span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
  border: 1px solid #f64747;
}
span.flatpickr-day.startRange,
span.flatpickr-day.prevMonthDay.startRange,
span.flatpickr-day.nextMonthDay.startRange,
span.flatpickr-day.endRange,
span.flatpickr-day.prevMonthDay.endRange,
span.flatpickr-day.nextMonthDay.endRange {
  border-color: #4f99ff;
}
span.flatpickr-day.today,
span.flatpickr-day.prevMonthDay.today,
span.flatpickr-day.nextMonthDay.today,
span.flatpickr-day.selected,
span.flatpickr-day.prevMonthDay.selected,
span.flatpickr-day.nextMonthDay.selected {
  z-index: 2;
}
.rangeMode .flatpickr-day {
  margin-top: -1px;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.flatpickr-weekwrapper span.flatpickr-day {
  border: 0;
  margin: -1px 0 0 -1px;
}
.hasWeeks .flatpickr-days {
  border-right: 0;
}

@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  span.flatpickr-day {
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}

.filter-list .badge {
  font-weight: 500 !important;
  padding: 0.5rem !important;
  font-size: 1rem !important;
}
.filter-list .badge:hover {
  cursor: pointer;
}
.avatar {
  height: 40px;
  width: 40px;
}
.avatar-title {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

/* text */
.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.9rem;
}
.text-base {
  font-size: 1rem;
}

.download i.fa {
  font-size: 50px;
  transition: all 0.2s ease-in-out;
}
.downloadIcon:hover {
  cursor: pointer;
}
.form-switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.form-switch i {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: #e6e6e6;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.form-switch i::before {
  content: "";
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  /* background-color: #fff; */
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background-color: #4bd763;
}

.form-switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}
.title-width .modal-title {
  width: 75%;
}
.min-h-100vh {
  min-height: 100vh;
}
